<template>
  <div class="book-user border-radius-6 box-shadow-light-grey padding-20">
    <div class="operation padding-bottom-20">
      <el-select
        v-model="params.accountChannelId"
        filterable
        clearable
        style="width: 180px"
        reserve-keyword
        placeholder="选择公众号"
      >
        <el-option
          v-for="(item, index) in officialList"
          :label="item.channelName"
          :value="item.id"
          :key="index"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
      <el-select
        class="margin-left-twentyFour"
        style="width: 180px"
        v-model="params.adminUserId"
        filterable
        clearable
        placeholder="选择用户"
      >
        <el-option
          v-for="item in userList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        class="margin-left-twentyFour"
        v-model="params.key"
        filterable
        clearable
        style="width: 180px"
        reserve-keyword
        placeholder="选择属性"
      >
        <el-option
          v-for="(item, index) in userAttributeList"
          :label="item.value"
          :value="item.key"
          :key="index"
        >
        </el-option>
      </el-select>
      <el-input
        clearable
        type="text"
        placeholder="请输入属性值"
        class="margin-left-twentyFour"
        v-model="params.value"
        style="width: 200px"
      />
      <el-button
        type="primary"
        @click="handlePageChange(page)"
        class="margin-left-twentyFour"
      >
        搜索</el-button
      >
    </div>

    <div class="custom-table" v-loading="loading">
      <el-table
        :data="dataList"
        style="width: 100%"
        height="calc(100vh - 300px)"
        ref="dataTable"
      >
        <el-table-column
          label="openid"
          prop="openId"
          width="300"
        ></el-table-column>
        <el-table-column
          label="所在公众号"
          prop="channelName"
          width="150"
        ></el-table-column>
        <el-table-column label="用户属性" prop="attributes" min-width="300">
          <template slot-scope="data">
            <template v-for="(item, index) in data.row.attributes">
              <!--  v-if="userAttributes[item.key]['base']" -->
              <el-tag
                v-if="userAttributes[item.key]['base']"
                style="margin-right: 4px"
                :key="index"
                type="warning "
              >
                {{ userAttributes[item.key]["name"] }}:{{ item.value }}
              </el-tag>
              <el-tag
                v-else
                style="margin-right: 4px"
                :key="index"
                type="success"
              >
                {{ userAttributes[item.key]["name"] }}:{{ item.value }}
              </el-tag>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getUserAttribute } from "@/api/user.js";
import { userAttributes, userAttributeList } from "@/assets/js/options";

export default {
  name: "BookUser",
  data() {
    return {
      dataList: [],
      loading: false,
      page: 1,
      pageSize: 15,
      total: 0,
      params: {},
      userAttributes,
      userAttributeList,
    };
  },
  computed: {
    ...mapGetters(["officialList", "userList"]),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      getUserAttribute({
        ...this.params,
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page = this.page) {
      this.page = page;
      this.initData();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.el-input {
  width: 200px;
}
.book-user {
  background-color: #fff;

  .custom-table {
    // width: calc( 100vw - 650px);
  }
}

.custom-filter {
  .filter-list {
    .filter-list-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      > span {
        width: 150px;
      }
      > :nth-child(2) {
        flex-grow: 1;
      }
    }
  }
  .button-list {
    text-align: center;
  }
}
</style>
